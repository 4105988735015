import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { HttpLoaderFactory, jwtOptionsFactory, MissingTranslationFactory } from '@core/factories';
import { TranslateModule, TranslateLoader, TranslatePipe, MissingTranslationHandler } from '@ngx-translate/core';
import {
    AuthenticationService,
    GeneralDataService,
    ImageDataService,
    GlobalsService,
} from '@core/services';
import { AuthorizationGuard } from './guards';

@NgModule({
    imports: [
        CommonModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                deps: [AuthenticationService],
                useFactory: jwtOptionsFactory
            }
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            useDefaultLang: false,
            missingTranslationHandler: {
                provide: MissingTranslationHandler, useFactory: MissingTranslationFactory, deps: [HttpClient]
            }
        })
    ],
    providers: [
        AuthorizationGuard,
        TranslatePipe,
        GeneralDataService,
        ImageDataService,
        GlobalsService,
    ]
})
export class CoreModule { 
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('Core Module has already been loaded.');
        }
    }
}
