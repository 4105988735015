import { NgModule } from '@angular/core';

import { FeatherModule } from '@feather/feather.module';
import {
    X,
} from '@shared/icon/icons';

// Select some icons (use an object, not an array)
const icons = {
    X
};

@NgModule({
    imports: [
        FeatherModule.pick(icons)
    ],
    exports: [
        FeatherModule
    ],
    declarations: []
})
export class IconModule { }
