import { UrlResolver } from '@core/utils';
import { Injectable } from '@angular/core';
import { GeneralDataService } from './general-data.service';
import { EndPoint } from '@core/constants';
import { Observable } from 'rxjs';

@Injectable()
export class ImageDataService extends GeneralDataService {
    getAnonymousImageUrl(blobId: string, organisationId: number, code: string): string {
        return `${UrlResolver.getUrl(EndPoint.image)}/${organisationId}/${code}/${blobId}`;
    }

    getImageUrl(blobId: string, organisationId: number): string {
        return `${UrlResolver.getUrl(EndPoint.image)}/${organisationId}/${blobId}`;
    }

    getCachedImageUrl(blobId: string, organisationId: number): string {
        const nonCachedUrl = this.getImageUrl(blobId, organisationId);

        return `${nonCachedUrl}/cached`;
    }
}

export interface ImageUploadResponse {
    id: number;
    blobId: string;
}
