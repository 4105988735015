import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlResolver } from '@core/utils';
import { EndPointType } from '@core/constants/endpoints';

@Injectable({
    providedIn: 'root'
})
export class GeneralDataService {

    constructor(private http: HttpClient) { }

    getGenericItem<T>(endpointType: EndPointType, path: string): Observable<T> {
        const url = UrlResolver.getUrl(endpointType, path);
        return this.http.get<T>(url);
    }

    getGenericFile(endpointType: EndPointType, path: string): Observable<Blob> {
        const url = UrlResolver.getUrl(endpointType, path);
        return this.http.get<Blob>(url, { responseType: 'blob' as 'json' });
    }

    postGenericItem<T, U>(endpointType: EndPointType, path: string, item: T): Observable<U> {
        const url = UrlResolver.getUrl(endpointType, path);
        return this.http.post<U>(url, item);
    }

    postGenericItemWithResponse<T, U>(endpointType: EndPointType, path: string, item: T): Observable<U> {
        const url = UrlResolver.getUrl(endpointType, path);
        return this.http.post<U>(url, item);
    }

    postJSON<T>(endpointType: EndPointType, path: string, item: string): Observable<T> {
        const url = UrlResolver.getUrl(endpointType, path);
        return this.http.post<T>(url, item, { headers: { 'Content-Type': 'application/json' } });
    }

    postFormData<T>(endpointType: EndPointType, path: string, data: FormData): Observable<T> {
        const url = UrlResolver.getUrl(endpointType, path);
        return this.http.post<T>(url, data);
    }

    deleteGenericItem<T>(endpointType: EndPointType, path: string): Observable<T> {
        const url = UrlResolver.getUrl(endpointType, path);
        return this.http.delete<T>(url);
    }
}
