import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GlobalsService {
    canLockScreenOrientation = true;

    lockScreenOrientation(): void {
        if (screen.orientation?.lock !== undefined && this.canLockScreenOrientation) {
            screen.orientation.lock('portrait').catch(() => { this.canLockScreenOrientation = false; });
        }
    }

    unlockScreenOrientation(): void {
        if (screen.orientation?.unlock !== undefined && this.canLockScreenOrientation) {
            screen.orientation.unlock();
        }
    }
}
