export type EndPointType = 'account' | 'briefing' | 'identity'
    | 'translation' | 'image' | 'organisation'
    | 'events' | 'wiki' | 'checklist' | 'users'
    | 'chat' | 'tasks' | 'notifications'
    | 'documents' | 'reports' | 'dashboards'
    | 'integrations' | 'video';

export class EndPoint {
    static get account(): EndPointType { return 'account'; }
    static get briefing(): EndPointType { return 'briefing'; }
    static get identity(): EndPointType { return 'identity'; }
    static get translation(): EndPointType { return 'translation'; }
    static get image(): EndPointType { return 'image'; }
    static get organisation(): EndPointType { return 'organisation'; }
    static get events(): EndPointType { return 'events'; }
    static get wiki(): EndPointType { return 'wiki'; }
    static get checklist(): EndPointType { return 'checklist'; }
    static get users(): EndPointType { return 'users'; }
    static get chat(): EndPointType { return 'chat'; }
    static get tasks(): EndPointType { return 'tasks'; }
    static get notifications(): EndPointType { return 'notifications'; }
    static get documents(): EndPointType { return 'documents'; }
    static get reports(): EndPointType { return 'reports'; }
    static get dashboards(): EndPointType { return 'dashboards'; }
    static get integrations(): EndPointType { return 'integrations'; }
    static get video(): EndPointType { return 'video'; }
}
