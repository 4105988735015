export class StringGenerator {
    public static generateRandomString(length: number = 50): string {
        // Valid bitwise usecase.
        // eslint-disable-next-line no-bitwise
        return [...Array(length)].map(_ => (~~(Math.random() * 36)).toString(36)).join('');
    }
}

export function ReplaceWordInString(originalValue: string | undefined, wordToReplace: string, wordToReplaceWith: string): string {
    if (!originalValue) {
        return '';
    }

    const regexStart = '(?<![<"])';
    const regexEnd = '(?![>"])';

    // Check if the first or last character of the word we are replacing is an actual word character; if not alter the word boundary
    // of the regex.
    let wordRegex = `\\b${wordToReplace}\\b`;

    if (wordToReplace && wordToReplace.trim().length > 0) {
        wordToReplace = wordToReplace.trim();
        const firstLetter = wordToReplace[0];
        const lastLetter = wordToReplace[wordToReplace.length - 1];
        let replaceStart = false;
        let replaceEnd = false;

        if (!firstLetter.match('[A-Za-z0-9_]')) {
            replaceStart = true;
        }

        if (!lastLetter.match('[A-Za-z0-9_]')) {
            replaceEnd = true;
        }

        if (replaceStart && replaceEnd) {
            wordRegex = `(?<![A-Za-z0-9_])${wordToReplace}(?![A-Za-z0-9_])`;
        } else if (replaceStart) {
            wordRegex = `(?<![A-Za-z0-9_])${wordToReplace}\\b`;
        } else if (replaceEnd) {
            wordRegex = `\\b${wordToReplace}(?![A-Za-z0-9_])`;
        }
    }


    const regex = new RegExp(`${regexStart}${wordRegex}${regexEnd}`, 'gmi');
    return originalValue.replace(regex, wordToReplaceWith);
}
