<div class='background'></div>

<div class='container foreground'>
    <div class='logo-container mt-3 mb-4'>
        <cp-logo color='white' maxHeight='40px' maxWidth='40px'></cp-logo>
    </div>
    <div class='title-container'>
        <h1>
            <span [translate]='"general.text.welcome"'>
                Welcome
            </span>
            <span>
                {{ firstName }}
            </span>
        </h1>
        <h2>
            -
            <span [translate]='"organisation.text.select_screen_slogan"'>
                Do you also want to peak at the right moment?
            </span>
        </h2>
    </div>
    <button mat-raised-button class='col-12 cp-button' color='accent' (click)='signout()'>
        <span [translate]='"general.routes.signout"'>
            Sign out
        </span>
    </button>
</div>
    