<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 133.04 155.17" [style.max-width]="maxWidth || 'default'"  [style.max-height]="maxHeight || 'initial'">
    <g id="Layer_2" data-name="Layer 2">
        <g id="Laag_1" data-name="Laag 1">
            <path [ngClass]="{'cp-green': color === 'primary', 'white': color === 'white' }" d="M105,102.2a2,2,0,0,0-2.82,0,34.81,34.81,0,1,1,0-49.22A2,2,0,0,0,105,53l27.43-27.43a2,2,0,0,0,0-2.83A77.59,77.59,0,1,0,77.59,155.17a77.1,77.1,0,0,0,54.86-22.72,2,2,0,0,0,0-2.83Zm7.7-62.58a51.73,51.73,0,1,0-1.18,77c.4-.35.8-.7,1.19-1.06l6.31,6.31A60.66,60.66,0,1,1,77.59,16.92,60.24,60.24,0,0,1,119,33.31Zm-73.93,38a38.79,38.79,0,0,0,64.78,28.81l6.33,6.32-1,.89a47.75,47.75,0,1,1,1-71.16l-6.32,6.32A38.79,38.79,0,0,0,38.79,77.59Zm38.8,73.58a73.59,73.59,0,1,1,50.6-127l-6.32,6.32A64.66,64.66,0,1,0,77.59,142.26a64.25,64.25,0,0,0,44.28-17.57l6.32,6.33A73.09,73.09,0,0,1,77.59,151.17Z"/>
        </g>
    </g>
</svg>

<h1 *ngIf="translate" class="mt-2" [translate]="translate">
    <ng-content></ng-content>
</h1>
