import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from '@core/services';

@Component({
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements AfterViewInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private auth: AuthenticationService,
    ) { }

    get firstName(): string | undefined {
        return this.auth.claims?.given_name;
    }

    ngAfterViewInit(): void {
        document.querySelector('body')?.classList.add('cp-blue');
    }

    ngOnDestroy(): void {
        document.querySelector('body')?.classList.remove('cp-blue');
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    signout(): void {
        this.auth.revokeAuthentication();
    }
}
