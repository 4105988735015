import { Component } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';

import { AuthenticationService } from '@core/services';
import { environment } from '@env';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

    initialisingLanguage = false;
    animation = 'animation';

    get isWebkit(): boolean {
        return navigator.userAgent.indexOf('Chrome') !== -1
            || this.isSafari
            || navigator.userAgent.indexOf('Opera') !== -1;
    }

    get isSafari(): boolean {
        return navigator.userAgent.indexOf('Safari') !== -1;
    }

    get isIOS(): boolean {
        return /iPad|iPhone|iPod/.test(navigator.userAgent);
    }

    get isStandaloneiOS(): boolean {
        const standalone = 'standalone';

        const navigator: any = window.navigator;

        return ('standalone' in window.navigator) && navigator[standalone];
    }

    constructor(
        translate: TranslateService, auth: AuthenticationService, overlayContainer: OverlayContainer,
    ) {
        this.initialisingLanguage = true;
        translate.setDefaultLang('gb');
        let chosenLanguage = localStorage.getItem('lang') || auth.claims?.language_code;
        if (!chosenLanguage) {
            const preferedLanguage = navigator.language.toUpperCase();
            // If the user has dutch selected as their prefered language default to dutch
            if (preferedLanguage === 'NL' || preferedLanguage === 'NL-NL' || preferedLanguage === 'NL-BE') {
                chosenLanguage = 'nl';
            }
        }

        translate.use(chosenLanguage || 'gb').pipe(take(1)).subscribe({
            next: () => this.initialisingLanguage = false,
            error: () => this.initialisingLanguage = false
        });

        const overlayContainerClasses = ['cp-light', 'wrapper'];

        if (screen.width > 500) {
            window.location.href = environment.cms;
        } 

        if (this.isWebkit) {
            overlayContainerClasses.push('webkit');
        }

        if (this.isSafari) {
            overlayContainerClasses.push('safari');
        }

        if (this.isIOS) {
            overlayContainerClasses.push('ios');
        }

        overlayContainer.getContainerElement()
            .classList.add(...overlayContainerClasses);
    }

    prepareRoute(outlet: RouterOutlet): string {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData[this.animation];
    }
}
