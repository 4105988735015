import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import {
    LogoComponent, ConfirmDialogComponent, DelayedSpinnerComponent, ImageComponent,
    ToggleablePasswordInputFieldComponent, OverlayService, ImageViewerOverlayComponent,
} from './components';
import {
    AutosizeDirective, MatButtonLoadingDirective
} from './directives';
import { SecurePipe, LocalizedDatePipe } from './pipes';
import { IconModule, CatchphraseIconComponent } from './icon';

@NgModule({
    declarations: [
        LogoComponent,
        ConfirmDialogComponent,
        DelayedSpinnerComponent,
        ImageComponent,
        CatchphraseIconComponent,
        ToggleablePasswordInputFieldComponent,
        ImageViewerOverlayComponent,
        MatButtonLoadingDirective,
        AutosizeDirective,
        LocalizedDatePipe,
        SecurePipe,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        IconModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        RouterModule,
        MatSnackBarModule,
        OverlayModule,
    ],
    providers: [
        OverlayService,
    ],
    exports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        LogoComponent,
        MatIconModule,
        IconModule,
        MatProgressSpinnerModule,
        MatButtonLoadingDirective,
        CatchphraseIconComponent,
        SecurePipe,
        MatDialogModule,
        MatSnackBarModule,
        LocalizedDatePipe,
        AutosizeDirective,
        ConfirmDialogComponent,
        DelayedSpinnerComponent,
        ImageComponent,
        ToggleablePasswordInputFieldComponent,
    ]
})
export class SharedModule { }
